import React from "react";
import {
  getUser,
  User,
  setUserToken,
  logout as providerLogout,
} from "../auth-provider";

interface AuthContextProps {
  user: User | null;
  loginUser: (token: string) => Promise<void>;
  logout: () => void;
}
const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  loginUser: async (token: string) => {},
  logout: () => {},
});
AuthContext.displayName = "AuthContext";

function AuthProvider(props: any) {
  const [user, setUser] = React.useState<User | null>(null);
  React.useEffect(() => {
    const run = async () => {
      const user = await getUser();
      if (user) {
        setUser(user);
      }
    };
    run();
  }, []);

  const loginUser = React.useCallback(async (token: string) => {
    setUserToken(token);
    const user = await getUser();
    setUser(user);
  }, []);

  const logout = React.useCallback(() => {
    providerLogout();
    setUser(null);
  }, []);

  // todo think about spinner handling

  return (
    <AuthContext.Provider value={{ user, loginUser, logout }} {...props} />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
