import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ReactQueryConfigProvider,
  ReactQueryConfig,
  QueryCache,
  ReactQueryCacheProvider,
} from "react-query";
import { AuthProvider } from "./auth-context";

const queryCache = new QueryCache();
const queryConfig: ReactQueryConfig = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry(failureCount, error: any) {
      if (error.status === 404) return false;
      else if (failureCount < 2) return true;
      else return false;
    },
    cacheTime: 60000,
    staleTime: 60000,
  },
};

const AppProviders: React.FC = ({ children }) => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ReactQueryConfigProvider config={queryConfig}>
        <Router>
          <AuthProvider>{children}</AuthProvider>
        </Router>
      </ReactQueryConfigProvider>
    </ReactQueryCacheProvider>
  );
};

export { AppProviders };
