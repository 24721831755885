import React from "react";
import { AdminNavigation } from "./AdminNavigation";
import { Navigation } from "./Navigation";
const Layout: React.FC<any> = ({ children, type }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      {type === "admin" ? <AdminNavigation /> : <Navigation />}
      {/*{<Navigation />}*/}
      <div className="py-10">
        <main>
          <div className="max-w-full mx-auto sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
