import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { endOfMonth, format, startOfMonth, sub } from "date-fns";
import { getUserToken, logout } from "../auth-provider";
import { GoogleProperties } from "../domain/types";
import {
  Account,
  AdhocExport,
  APISchedule,
  BrandQueryExampleResult,
  Contentcheck,
  ContentDecay,
  Dimension,
  GoogleAuthentication,
  OptimizationWizard,
  Project,
  TitleOptimization,
  TrafficInsight,
} from "./api/types";

export class ApiClient {
  private axios: AxiosInstance;
  constructor(passedAxios?: AxiosInstance) {
    if (passedAxios !== undefined) {
      this.axios = passedAxios;
    } else {
      this.axios = axios.create({
        baseURL: `${process.env.REACT_APP_API_HOST}/api/v1`,
      });
    }
    this.axios.interceptors.request.use(this.handleRequest);
    this.axios.interceptors.response.use((response: any) => {
      return response;
    }, this.handleError);
  }

  private handleError(error: AxiosError) {
    if (error.response?.status === 401) {
      logout();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
  private handleRequest(config: AxiosRequestConfig) {
    config.headers["Authorization"] = `Bearer ${getUserToken()}`;
    return config;
  }

  public async updateSearchVolume(value: number, id: any) {
    return this.axios
      .post("/keyword/update-search-volume", {
        value,
        id,
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  public async login(login: string, password: string) {
    return this.axios.post("/auth/login", {
      email: login,
      password,
    });
  }

  public async forgotPassword(email: string) {
    return this.axios
      .post("/auth/generate-new-password", {
        email,
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  public async changePassword(
    account_id: string,
    old_password: string,
    new_password: string
  ) {
    return this.axios
      .post("/auth/update-password", {
        account_id,
        old_password,
        new_password,
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  public async getProjects() {
    return this.axios.get<Project[]>("/projects");
  }
  public async getAccounts() {
    return this.axios.get<Account[]>("/accounts");
  }

  public saveGoogleAuth({ code, state }: { code: string; state: string }) {
    return this.axios.post<{ url: string }>("/auth/google/callback", {
      code,
      state,
    });
  }

  public getGoogleAuthURL() {
    return this.axios.get<{ url: string }>("/auth/google");
  }

  public async getGoogleAuths() {
    return this.axios.get<GoogleAuthentication[]>("/google-authentications");
  }

  public async getGoogleProperties(authId: string) {
    const props = await this.axios.post<GoogleProperties>(
      "/google-properties",
      {
        auth_id: authId,
      }
    );
    return props.data.siteEntry;
  }
  public async getBrandQueryExamples(params: {
    auth_id: string;
    country: string;
    excludes: string[];
    property_url: string;
    homepage_url: string;
  }) {
    return await this.axios.post<BrandQueryExampleResult>(
      "/google-search-analytics/brand-exclude",
      { ...params }
    );
  }

  public async createProject(params: {
    auth_id: string;
    country: string;
    brand_queries: string[];
    name: string;
    properties: string[];
  }) {
    try {
      return await this.axios.post<Project>("/projects", { ...params });
    } catch (error) {
      return error;
    }
  }

  public async deleteProject({ projectId }: { projectId: string }) {
    return this.axios.delete(`/projects/${projectId}`);
  }

  public async getProject({ projectId }: { projectId: string }) {
    return this.axios.get(`/projects/${projectId}`);
  }

  public async deleteKeywordMap({ projectId }: { projectId: string }) {
    return this.axios.delete(`/delete-keyword-map/${projectId}`);
  }

  public async deleteKeywordMapList({
    projectId,
    keywordmapId,
  }: {
    projectId: string;
    keywordmapId: string;
  }) {
    return this.axios.delete(
      `/projects/${projectId}/keyword-map/${keywordmapId}`
    );
  }

  public async editProject(
    projectId: string,
    params: {
      country: string;
      brand_queries: string[];
      name: string;
      user_agent: string;
    },
    project: any
  ) {
    try {
      return await this.axios.put<Project>(`/projects/${projectId}`, {
        ...params,
      });

    } catch (error) {
      return error;
    }
  }

  public async downloadContentchecksExport(params: { projectId: string }) {
    return this.axios.get(
      `projects/${params.projectId}/exports/contentchecks`,
      { responseType: "arraybuffer" }
    );
  }
  public async downloadAdhocExport(params: { adhocExportId: string }) {
    return this.axios.get(`ad-hoc/${params.adhocExportId}/download`, {
      responseType: "arraybuffer",
    });
  }

  public async downloadContentcheckSearchQueries(params: {
    projectId: string;
    contentcheckId: string;
  }) {
    return this.axios.get(
      `projects/${params.projectId}/contentchecks/${params.contentcheckId}/exports/search-queries`,
      { responseType: "arraybuffer" }
    );
  }

  public async downloadContentcheckSearchTerms(params: {
    projectId: string;
    contentcheckId: string;
    type: string;
  }) {
    return this.axios.get(
      `projects/${params.projectId}/contentchecks/${params.contentcheckId}/exports/terms?table=${params.type}`,
      { responseType: "arraybuffer" }
    );
  }

  public async downloadApiScheduleRawExport(params: { exportId: string }) {
    return this.axios.get(`raw-exports/${params.exportId}/download`, {
      responseType: "arraybuffer",
    });
  }
  public async downloadContentcheckNewIntents(params: {
    projectId: string;
    contentcheckId: string;
  }) {
    return this.axios.get(
      `projects/${params.projectId}/contentchecks/${params.contentcheckId}/exports/new-intents`,
      { responseType: "arraybuffer" }
    );
  }

  public async downloadNewIntents(params: { projectId: string }) {
    return this.axios.get(`projects/${params.projectId}/exports/new-intents`, {
      responseType: "arraybuffer",
    });
  }

  public async getContentchecks(params: {
    projectId: string;
    showArchive: boolean;
  }) {
    return await this.axios.get<Contentcheck[]>(
      `/projects/${params.projectId}/contentchecks?listType=${params.showArchive ? "1" : "0"
      }`
    );
  }
  public async getTitleOptimizations(params: { projectId: string }) {
    return await this.axios.get<TitleOptimization[]>(
      `/title-optimizer/${params.projectId}`
    );
  }
  public async getAPISchedules(params: {
    projectId: string;
    showArchive: boolean;
  }) {
    return await this.axios.get<APISchedule[]>(
      `/query-schedule/${params.projectId}?listType=${params.showArchive ? "1" : "0"
      }`
    );
  }
  public async getAdhocExports(params: {
    projectId: string;
    showArchive: boolean;
  }) {
    return await this.axios.get<AdhocExport[]>(
      `/ad-hoc/${params.projectId}?listType=${params.showArchive ? "1" : "0"}`
    );
  }

  public async deleteAPISchedule(params: {
    projectId: string;
    apiScheduleId: string;
  }) {
    return await this.axios.delete<null>(
      `/query-schedule/${params.projectId}/${params.apiScheduleId}`
    );
  }

  public async createAdhocExport(params: {
    projectId: string;
    country: Dimension;
    device?: Dimension;
    page: Dimension;
    dimensions: string[];
    excludeBrandQueries?: number;
    queries?: Dimension[];
    searchType: string;
    dates: { start: string; end: string };
    rowLimit: number;
  }) {
    //if (params.searchType !== "google_news" && params.searchType !== "discover") {
    //  completeparams.queries= params.queries
    //  completeparams.exclude_brand_queries= params.excludeBrandQueries
    //}

    //if (params.searchType !== "discover"){
    //  completeparams.device= params.device
    //}
    try {
      return await this.axios.post<{ message: string }>(`/ad-hoc`, params);
    } catch (error) {
      return error
    }
  }
  public async getTrafficInsights(params: {
    projectId: string;
    showArchive: boolean;
  }) {
    return await this.axios.get<TrafficInsight[]>(
      `/traffic-insights/${params.projectId}?listType=${params.showArchive ? "1" : "0"
      }`
    );
  }
  public async createTrafficInsight(params: {
    projectId: string;
    page: {
      operator: string,
      label: string,
    }
    excludeBrandQueries: boolean,
    device: string;
    period: "last_7" | "last_14" | "last_28" | "last_month";
    minImpressions: number;
    rowLimit: number;
  }) {
    try {
    return await this.axios.post<{ message: string }>(`/traffic-insights`, {
      project_id: params.projectId,
      page: { operator: params.page.operator, label: params.page.label },
      excludeBrandQueries: params.excludeBrandQueries,
      device: params.device,
      period: params.period,
      min_impressions: params.minImpressions,
      row_limit: params.rowLimit,
    });
  } catch (error) {
      return error
  }
  }

  public async downloadTrafficInsight(params: { trafficInsightId: string }) {
    return this.axios.get(
      `traffic-insights/${params.trafficInsightId}/download`,
      {
        responseType: "arraybuffer",
      }
    );
  }

  public async getOptimizationWizards(params: {
    projectId: string;
    showArchive: boolean;
  }) {
    return await this.axios.get<OptimizationWizard[]>(
      `/optimization-wizards/${params.projectId}?listType=${params.showArchive ? "1" : "0"
      }`
    );
  }
  public async getOptimizationWizard(params: {
    projectId: string;
    optimizationWizardId: string;
  }) {
    return await this.axios.get<OptimizationWizard>(
      `/optimization-wizards/${params.projectId}/${params.optimizationWizardId}`
    );
  }

  public async createOptimizationWizard(params: {
    projectId: string;
    device: Dimension;
    page: Dimension;
    country: Dimension;
    searchType: string;
    maxResults: number;
    min_ctr: number;
  }) {
    try {
          return await this.axios.post<{ message: string }>(
      `/optimization-wizards/${params.projectId}`,
      {
        device: params.device,
        page: params.page,
        country: params.country,
        search_type: "web",
        max_results: params.maxResults,
        min_ctr: params.min_ctr
      }
    );
  } catch (error) {
      return error
  }
  }
  public async getTitleOptimization(params: {
    projectId: string;
    titleOptimizationId: string;
  }) {
    return await this.axios.get<TitleOptimization>(
      `/title-optimizer/${params.projectId}/${params.titleOptimizationId}`
    );
  }
  public async getAPISchedule(params: {
    projectId: string;
    apiScheduleId: string;
  }) {
    return await this.axios.get<APISchedule>(
      `/query-schedule/${params.projectId}/${params.apiScheduleId}`
    );
  }

  public async createAPISchedule(payload: {
    projectID: string;
    title: string;
    description: string;
    excludeBrandQueries?: number | null;
    queries?: Dimension | null; // comma separated values -.-
    device: Dimension;
    country: Dimension;
    page: Dimension;
    dimensions: string[];
    frequency: string;
    aggregation_type: string;
    searchType: string;
    rowLimit: number;
  }) {
    const completePayload: any = {
      projectID: payload.projectID,
      title: payload.title,
      description: payload.description,
      dimensions: payload.dimensions,
      country: payload.country,
      page: payload.page,
      row_limit: 0, // is not being used anymore.
      frequency: payload.frequency,
      aggregation_type: payload.aggregation_type,
      search_type: payload.searchType,
      rowLimit: payload.rowLimit,
    };

    if (
      payload.searchType !== "google_news" &&
      payload.searchType !== "discover"
    ) {
      completePayload.queries = payload.queries;
      completePayload.exclude_brand_queries = payload.excludeBrandQueries;
    }

    if (payload.searchType !== "discover") {
      completePayload.device = payload.device;
    }

    if (payload.country.operator == "nofilter") {
      completePayload.country.label = ""
    }

    const path = `/query-schedule`;
    try {
      return await this.axios.post<APISchedule>(path, completePayload);
    } catch (error) {
      return error
    }
  }

  public async updateAPISchedule(params: {
    title: string;
    description: string;
    frequency: string;
    projectId: string;
    scheduleId: string;
  }) {
    return await this.axios.put<Project>(
      `/query-schedule/${params.projectId}/${params.scheduleId}`,
      { ...params }
    );
  }
  public async getContentcheck(params: {
    projectId: string;
    contentcheckId: string;
  }) {
    return await this.axios.get<Contentcheck>(
      `/projects/${params.projectId}/contentchecks/${params.contentcheckId}`
    );
  }
  public async deleteContentcheck(params: {
    projectId: string;
    contentcheckId: string;
  }) {
    return await this.axios.delete<null>(
      `/projects/${params.projectId}/contentchecks/${params.contentcheckId}`
    );
  }

  public async deleteOptimizationWizards(params: {
    projectId: string;
    optimizationWizardsId: string;
  }) {
    return await this.axios.delete<null>(
      `/projects/${params.projectId}/optimization-wizards/${params.optimizationWizardsId}`
    );
  }

  public async deleteAdhocExports(params: {
    projectId: string;
    adhocExportId: string;
  }) {
    return await this.axios.delete<null>(
      `/projects/${params.projectId}/ad-hoc/${params.adhocExportId}`
    );
  }

  public async deleteTrafficInsights(params: {
    projectId: string;
    trafficInsightId: string;
  }) {
    return await this.axios.delete<null>(
      `/projects/${params.projectId}/traffic-insights/${params.trafficInsightId}`
    );
  }

  public async updateContentcheck(params: {
    projectId: string;
    contentcheck: Contentcheck;
  }) {
    return await this.axios.put<Contentcheck>(
      `/projects/${params.projectId}/contentchecks/${params.contentcheck.id}`,
      { type: "save", ...params.contentcheck }
    );
  }

  public async updateSource(params: {
    projectId: string;
    contentcheck: Contentcheck;
  }) {
    return await this.axios.put<Contentcheck>(
      `/projects/${params.projectId}/contentchecks/${params.contentcheck.id}`,
      { type: "overwrite", ...params.contentcheck }
    );
  }

  public async addNewDataSet(
    projectID: string,
    payload: {
      url: string;
      isPrioURL: string;
      topKeyword: string;
      customerJourneyPhase: string;
      tags: string[];
      secondaryKeywords: string;
    }
  ) {
    const data = {
      project_id: projectID,
      url: payload.url,
      prio_url: payload.isPrioURL,
      top_keyword: payload.topKeyword,
      customer_journey: payload.customerJourneyPhase,
      tags: payload.tags,
      keyword: payload.secondaryKeywords,
    };

    try {
      await this.axios.post<Contentcheck>(`/keyword-map/add`, data);
    } catch (error) {
      return error;
    }
  }

  public async getKeywordMap(
    projectId: string,
    query: any,
    currentPage: number,
    refreshCall: number,
    sortBy: string
  ) {
    return await this.axios
      .get(
        `/keyword-map/get/${projectId}?search=${query.searchValue}&terms=${query.searchDropdownValue}&page=${currentPage}&condition=${query.includeExclude}&refresh=${refreshCall}&header=${sortBy}`
      )
      .then((res) => res.data);
  }

  public async getKeywordMapList(params: { projectId: string }) {
    return await this.axios.get(`/projects/${params.projectId}/keyword-map`);
  }

  public async getAllUsers() {
    return await this.axios
      .get(`/user_managements/user-list`)
      .then((res) => res.data);
  }

  public async starTopKeyword(id: any) {
    return await this.axios
      .put(`/keyword-map/update-keyword`, { id })
      .then((res) => res.data);
  }

  public async uploadExcelFile(formData: any) {
    try {
      return await this.axios
        .post(`/stop-words/add`, formData)
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async deleteKeyword(rowId: string, type: string) {
    try {
      return await this.axios
        .delete(`/keyword-delete/${rowId}/${type}`)
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async deleteStopWord(id: string) {
    try {
      return await this.axios
        .delete(`/delete-stop-words/${id}`)
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async dropAll(id: string) {
    try {
      return await this.axios.delete(`/drop-all/${id}`).then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async addCrawler(data: any, id: string) {
    try {
      return await this.axios

        .post("/settings/add-crawler", {
          user_agent: data.default_user_agent,
          delay: Number(data.crawl_delay),
          created_by: id,
        })
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async getCrawler() {
    try {
      return await this.axios
        .get("/settings/get-crawler")
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async deleteUser(id: string) {
    try {
      return await this.axios
        .delete(`user_managements/user-delete/${id}`)
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async addNewUser(data: any) {
    try {
      return await this.axios
        .post(`user_managements/user-create`, {
          email: data.email,
          name: data.name,
          password: data.password,
          project_limit: Number(data.project_limit),
          account_type: data.account_type
        })
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async editUser(data: any, id: any) {
    try {
      return await this.axios
        .post(`user_managements/user-update`, {
          name: data.name,
          password: data.password,
          project_limit: Number(data.project_limit),
          account_type: data.account_type,
          id: id,
        })
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async importCSV(formData: FormData) {
    try {
      return await this.axios
        .post(`/keyword-map-bulk-upload`, formData)
        .then((res) => res.data);
    } catch (error) {
      return error;
    }
  }
  public async updateTags(newData: any) {
    try {
      return await this.axios.put(
        `${process.env.REACT_APP_API_HOST}/api/v1/keyword-map/update-tags`,
        { ...newData }
      );
    } catch (error) {
      return error;
    }
  }

  public async updateChild(data: any) {
    try {
      return await this.axios.put(
        `${process.env.REACT_APP_API_HOST}/api/v1/keyword-map/update-child`,
        { ...data }
      );
    } catch (error) {
      return error;
    }
  }

  public async updateParent(data: any) {
    return await this.axios.put(
      `${process.env.REACT_APP_API_HOST}/api/v1/keyword-map/update-parent`,
      { ...data }
    );
  }

  public async updateName(id: any, name: any) {
    try {
      return await this.axios.post(
        `${process.env.REACT_APP_API_HOST}/api/v1/user_managements/user-update`,
        { id, name }
      );
    } catch (error) {
      return error;
    }
  }

  public async addBlankRow(data: any) {
    return await this.axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/v1/keyword-map/add-blank-row`,
        { ...data }
      )
      .catch((error) => error);
  }

  public async getByCountry(country: any) {
    return await this.axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/v1/get-stop-words-list/${country}`
      )
      .catch((error) => error);
  }

  public async getStopWordsByCountry(country: any) {
    return await this.axios
      .get(`${process.env.REACT_APP_API_HOST}/get-stop-words/${country}`)
      .catch((error) => error);
  }

  public async createKeywordMap(
    projectID: string,
    payload: {
      period: string;
      page: {
        operator: string;
        label: string;
      };
      minClicks: string;
      minImpressions: string;
      rowLimit: number
    }
  ) {
    let End = endOfMonth(sub(new Date(), { months: 1 }));
    let Start =
      payload.period == "last_month"
        ? startOfMonth(sub(new Date(), { months: 1 }))
        : startOfMonth(sub(new Date(), { months: 3 }));

    const data = {
      projectID: projectID,
      Dates: {
        Start: format(Start, "yyyy-MM-dd"),
        End: format(End, "yyyy-MM-dd"),
      },
      page: { operator: payload.page.operator, label: payload.page.label },
      minClicks: Number(payload.minClicks),
      minImpressions: Number(payload.minImpressions),
      rowLimit: Number(payload.rowLimit)
    };
    try {
      return await this.axios.post<Contentcheck>(`/keyword-map/create`, data);
    } catch (error) {
      return error;
    }
  }

  public async createContentcheck(
    projectID: string,
    payload: {
      url: string;
      dates: { start: Date; end: Date };
      excludeBrandQueries: boolean;
      excludeQueries: string[];
      minImpressions: string;
      minPosition: string;
      maxPosition: string;
      device: Dimension;
      country: Dimension;
      min_ctr?: number
    }
  ) {
    const formattedDates = {
      start: format(payload.dates.start, "yyyy-MM-dd"),
      end: format(payload.dates.end, "yyyy-MM-dd"),
    };

    const completePayload = {
      url: payload.url,
      country: payload.country,
      min_impressions: parseInt(payload.minImpressions, 10),
      max_position: parseInt(payload.maxPosition, 10),
      min_position: parseInt(payload.minPosition, 10),
      device: payload.device,
      exclude_queries: payload.excludeQueries,
      exclude_brand_queries: payload.excludeBrandQueries,
      projectID,
      dates: formattedDates,
      min_ctr: payload.min_ctr
    };

    try {
      return await this.axios.post<Contentcheck>(
        `/projects/${projectID}/contentchecks`,
        completePayload
      );
    } catch (error) {
      return error
    }
  }

  public async createContentDecay(
    projectID: string,
    payload: {
      title: string;
      country: Dimension;
      excludeBrandQueries: boolean;
      page: Dimension;
      device: Dimension;
      minimumClicks: number;
    }
  ) {
    try {  
    return await this.axios.post<ContentDecay>(
      `/projects/${projectID}/content-decay`,
      payload
    );
  } catch (error) {
      return error
  }
  }

  public async getContentDecayList(params: {
    projectId: string;
    showArchive: boolean;
  }) {
    return await this.axios.get<ContentDecay[]>(
      `/projects/${params.projectId}/content-decay?listType=${params.showArchive ? "1" : "0"
      }`
    );
  }

  public async getContentDecay(params: any) {
    return await this.axios.get<ContentDecay>(
      `/projects/${params.projectId}/content-decay/${params.contentdecayId
      }?search=${params.query.searchValue}&terms=${params.query.searchDropdownValue
      }&page=${params.currentPage}&condition=${params.query.includeExclude}${params.header ? "&header=" + params.header : ""
      }${params.startingPeriod ? "&start=" + params.startingPeriod : ""}${params.comparedPeriod ? "&compare=" + params.comparedPeriod : ""}`
    );
  }

  public async updateTitle({ title, projectId, contentdecayId }: any) {
    try {
      return await this.axios.put(
        `/projects/${projectId}/content-decay/${contentdecayId}`,
        { title }
      );
    } catch (error) {
      return error;
    }
  }

  public async deleteContentDecay(params: {
    projectId: string;
    contentdecayId: string;
  }) {
    return await this.axios.delete<null>(
      `/projects/${params.projectId}/content-decay/${params.contentdecayId}`
    );
  }

  public async exportContentDecayTable(params: { contentdecayId: string, projectId: string }) {
    return this.axios.get(`/content-decay/${params.contentdecayId}/download-csv`, {
      responseType: "arraybuffer",
    });
  }

  public async exportKeywordMapTable(params: { projectId: string}) {
    return this.axios.get(`/keyword-map/${params.projectId}/download-keyword-map`, {
      responseType: "arraybuffer",
    });
  }

  public async exportKeywordsTable(params: { projectId: string}) {
    return this.axios.get(`/keyword-map/${params.projectId}/download-keywords`, {
      responseType: "arraybuffer",
    });
  }

  public async getFreeAccountSettings() {
    try {
      return await this.axios.get("/account-setting").then((res) => res.data);
    } catch (error) {
      return error;
    }
  }

  public async saveFreeAccountSettings(data: any) {
    return await this.axios.post(`/account-setting`, data);
  }

  public async resyncCD(projectId: string) {
    return await this.axios.get(`/cd-update/${projectId}`);
  }

}

export default new ApiClient();
