/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Transition } from "@tailwindui/react";
import { useAuth } from "../context/auth-context";
import { useHistory, Link, generatePath } from "react-router-dom";
import { UserCircleOutline } from "heroicons-react";
import { routes } from "../routes";
import { useQueryCache } from "react-query";

export const AvatarDropdown: React.FC = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { logout } = useAuth();
  const history = useHistory();
  const queryCache = useQueryCache();
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!isDropdownVisible) return;
      setIsDropdownVisible(false);
    };

    document.addEventListener("click", handler);
    return () => document.removeEventListener("click", handler);
  }, [isDropdownVisible]);

  const handleLogout = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      logout();
      queryCache.clear({});
      history?.replace("/login");
    },
    [queryCache, history, logout]
  );

  return (
    <div className="relative">
      <div>
        <button
          ref={btnRef}
          className="flex text-sm border-2 border-transparent rounded-full active:outline-none focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        >
          <UserCircleOutline className="text-gray-400 h-8 w-8" />
        </button>
      </div>
      <Transition
        show={isDropdownVisible}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition easie-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg">
          <div className="flex flex-col place-items-center rounded-md bg-white shadow-xs">
            {/*<a*/}
            {/*  href="#"*/}
            {/*  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"*/}
            {/*>*/}
            {/*  Your Profile*/}
            {/*</a>*/}
            <Link
              to={generatePath(routes.account.path)}
              className="min-w-full text-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >
              Account
            </Link>
            <button
              onClick={handleLogout}
              className="min-w-full	px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            >
              Sign out
            </button>
            {/*{user?.accounts[0].account_id === "ea0e24c2-aa8f-4128-a64f-a2fc8ece2ef4" &&
              <>
                <Link
                  to={adminRoutes.crawlerOptions.path}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                >
                  Crawler Options
                </Link>
                <Link
                  to={adminRoutes.usersManagement.path}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                >
                  Users Management
                </Link>
                <Link
                  to={adminRoutes.stopWordsList.path}
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                >
                  Stop Words List
                </Link>
              </>
            }*/}
          </div>
        </div>
      </Transition>
    </div>
  );
};
