import React from "react";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { useParams } from "react-router-dom";
import ApiClient from "../utils/api-client";
import { Project } from "../utils/api/types";
import apiClient from "../utils/api-client";

const defaultEmptyProject: Project = {
  id: "",
  created_at: "",
  updated_at: "",
  name: "",
  country: "",
  brand_queries: [],
  properties: [],
  google_authentication_id: "",
  account_id: "",
  created_by: "",
  google_auth: {
    id: "",
    created_at: "",
    updated_at: "",
    email: "",
    access_token: "",
    refresh_token: "",
    expires_at: "",
  },
  tags: [],
  customer_journey: [],
  key_map: "",
  keymap_status: 0,
  user_agent: ""
};
const defaultEmptyProjectList: Project[] = [defaultEmptyProject];

export default function useProjects() {
  const { data, isFetched } = useQuery("projects", async () => {
    const { data } = await ApiClient.getProjects();
    return data;
  });
  if (isFetched) {
    return { data: [...data!] };
  }

  return { data: defaultEmptyProjectList };
}

interface CurrentProjectContextProps {
  setCurrentProjectId: (projectId: string) => void;
  project: Project | null;
}
const CurrentProjectContext = React.createContext<CurrentProjectContextProps>({
  setCurrentProjectId: (projectId: string) => { },
  project: null,
});
CurrentProjectContext.displayName = "CurrentProjectContext";

function CurrentProjectProvider(props: any) {
  const [currentProject, setCurrentProject] = React.useState<Project>(
    defaultEmptyProject
  );
  const { data: projects } = useProjects();
  // making sure that on the first render a project gets set as current
  React.useEffect(() => {
    if (currentProject.id === "" && projects.length > 0) {
      setCurrentProject(projects[0]);
    }
  }, [currentProject, projects]);

  return (
    <CurrentProjectContext.Provider
      value={{
        project: currentProject,
        setCurrentProjectId: (projectId: string) => {
          let newProject = projects.find((p) => p.id === projectId)!;
          if (newProject === undefined) {
            newProject = defaultEmptyProject;
          }
          setCurrentProject(newProject);
        },
      }}
      {...props}
    />
  );
}

function useCurrentProject() {
  const context = React.useContext(CurrentProjectContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentProject must be used within an CurrentProjectProvider"
    );
  }
  return { ...context };
}

function useSetCurrentProjectFromURL() {
  const { projectId } = useProjectIdFromURL();
  const { setCurrentProjectId } = useCurrentProject();
  React.useEffect(() => {
    if (!projectId) {
      return;
    }
    setCurrentProjectId(projectId);
  }, [projectId, setCurrentProjectId]);
}

function useProjectIdFromURL() {
  const params = useParams<{ projectId: string }>();
  const { projectId } = params;
  return {
    projectId,
  };
}

function useProjectDelete() {
  const queryCache = useQueryCache();
  const [deleteProject] = useMutation(
    ({ projectId }: { projectId: string }) => {
      return apiClient.deleteProject({ projectId });
    },
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(["projects"]);
      },
    }
  );
  return deleteProject;
}

function useDeleteKeywordMap() {
  const queryCache = useQueryCache();
  const [deleteKeywordMap] = useMutation(
    ({ projectId }: { projectId: string }) => {
      return apiClient.deleteKeywordMap({ projectId });
    },
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(["projects"]);
      },
    }
  );
  return deleteKeywordMap
}

export {
  useCurrentProject,
  CurrentProjectProvider,
  useSetCurrentProjectFromURL,
  useProjectIdFromURL,
  useProjectDelete,
  useDeleteKeywordMap,
};
