import React from "react";

type Route = {
  path: string;
  component: React.LazyExoticComponent<React.FC>;
  exact: boolean;
};

export const adminRoutes = {
  //adminDashboard: {
  //  path: "/admin-dashboard/",
  //  component: React.lazy(() => import("./pages/AdminDashboard")),
  //  exact: true,
  //},
  usersManagement: {
    path: "/users-management/",
    component: React.lazy(
      () => import("./pages/UsersManagement/UsersManagement")
    ),
    exact: true,
  },
  crawlerOptions: {
    path: "/crawler-options/",
    component: React.lazy(
      () => import("./pages/CrawlerOptions/CrawlerOptions")
    ),
    exact: true,
  },
  stopWordsList: {
    path: "/stop-words-list/",
    component: React.lazy(() => import("./pages/StopWordsList/StopWordsList")),
    exact: true,
  },
  freeUserSettings: {
    path: "/free-user-settings/",
    component: React.lazy(() => import("./pages/FreeUserSettings/FreeUserSettings")),
    exact: true,
  },
};

export const routes = {
  dashboard: {
    path: "/dashboard/",
    component: React.lazy(() => import("./pages/Dashboard")),
    exact: true,
  },
  createProject: {
    path: "/projects/create/",
    component: React.lazy(() => import("./pages/Projects/CreateProject")),
    exact: true,
  },
  editProject: {
    path: "/projects/edit/:projectId",
    component: React.lazy(() => import("./pages/Projects/EditProject")),
    exact: true,
  },
  listContentDecay: {
    path: "/content-decay/list/:projectId/",
    component: React.lazy(
      () => import("./pages/ContentDecay/ListContentDecay")
    ),
    exact: true,
  },
  createContentDecay: {
    path: "/content-decay/create/:projectId/",
    component: React.lazy(
      () => import("./pages/ContentDecay/CreateContentDecay")
    ),
    exact: true,
  },
  detailContentDecay: {
    path: "/content-decay/detail/:projectId/:contentdecayId/",
    component: React.lazy(
      () => import("./pages/ContentDecay/DetailContentDecay")
    ),
    exact: true,
  },
  listContentOptimization: {
    path: "/content-optimization/list/:projectId/",
    component: React.lazy(
      () => import("./pages/ContentOptimization/ListContentOptimization")
    ),
    exact: true,
  },
  createContentcheck: {
    path: "/content-optimization/create/:projectId/",
    component: React.lazy(
      () => import("./pages/ContentOptimization/CreateContentcheck")
    ),
    exact: true,
  },
  detailContentOptimization: {
    path: "/content-optimization/detail/:projectId/:contentcheckId/",
    component: React.lazy(
      () => import("./pages/ContentOptimization/DetailContentcheck")
    ),
    exact: true,
  },
  listTitleOptimization: {
    path: "/title-optimizer/list/:projectId/",
    component: React.lazy(
      () => import("./pages/TitleOptimization/ListTitleOptimization")
    ),
    exact: true,
  },
  detailTitleOptimization: {
    path: "/title-optimizer/detail/:projectId/:titleOptimizationId/",
    component: React.lazy(
      () => import("./pages/TitleOptimization/DetailTitleOptimization")
    ),
    exact: true,
  },
  listAPISchedule: {
    path: "/api-schedule/list/:projectId/",
    component: React.lazy(
      () => import("./pages/APIScheduler/ListAPIScheduler")
    ),
    exact: true,
  },
  createAPISchedule: {
    path: "/api-schedule/create/:projectId/",
    component: React.lazy(
      () => import("./pages/APIScheduler/CreateAPISchedule")
    ),
    exact: true,
  },
  listAdhocExport: {
    path: "/adhoc-exports/list/:projectId/",
    component: React.lazy(
      () => import("./pages/AdhocExports/ListAdhocExports")
    ),
    exact: true,
  },
  createAdhocExport: {
    path: "/adhoc-exports/create/:projectId/",
    component: React.lazy(
      () => import("./pages/AdhocExports/CreateAdhocExport")
    ),
    exact: true,
  },
  detailAPISchedule: {
    path: "/api-schedule/detail/:projectId/:apiScheduleId/",
    component: React.lazy(
      () => import("./pages/APIScheduler/DetailAPIScheduler")
    ),
    exact: true,
  },

  listOptimizationWizard: {
    path: "/optimization-wizard/list/:projectId/",
    component: React.lazy(
      () => import("./pages/OptimizationWizard/ListOptimizationWizard")
    ),
    exact: true,
  },
  createOptimizationWizard: {
    path: "/optimization-wizard/create/:projectId/",
    component: React.lazy(
      () => import("./pages/OptimizationWizard/CreateOptimizationWizard")
    ),
    exact: true,
  },
  detailOptimizationWizard: {
    path: "/optimization-wizard/detail/:projectId/:optimizationWizardId/",
    component: React.lazy(
      () => import("./pages/OptimizationWizard/DetailOptimizationWizard")
    ),
    exact: true,
  },
  listTrafficInsights: {
    path: "/traffic-insights/list/:projectId/",
    component: React.lazy(
      () => import("./pages/TrafficInsights/ListTrafficInsights")
    ),
    exact: true,
  },
  createTrafficInsights: {
    path: "/traffic-insights/create/:projectId/",
    component: React.lazy(
      () => import("./pages/TrafficInsights/CreateTrafficInsight")
    ),
    exact: true,
  },

  createKeywordMap: {
    path: "/keyword-map/create/:projectId/",
    component: React.lazy(() => import("./pages/KeywordMap/CreateKeywordMap")),
    exact: true,
  },

  AddKeywordMap: {
    path: "/keyword-map/add/:projectId/",
    component: React.lazy(() => import("./pages/KeywordMap/AddKeywordMap")),
    exact: true,
  },

  ListKeywordMapWizard: {
    path: "/keyword-map/list/:projectId/",
    component: React.lazy(
      () => import("./pages/KeywordMap/ListKeywordMapWizard")
    ),
    exact: true,
  },

  DetailKeywordMapWizard: {
    path: "/keyword-map/details/:projectId/",
    component: React.lazy(
      () => import("./pages/KeywordMap/DetailKeywordMapWizard")
    ),
    exact: true,
  },

  account: {
    path: "/account/",
    component: React.lazy(() => import("./pages/Account/Account")),
    exact: true,
  },

  googleAuthCallback: {
    path: "/auth/google/callback/",
    component: React.lazy(() => import("./pages/GoogleAuthCallback")),
    exact: true,
  },
};
