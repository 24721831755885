import React from "react";
import logo from "../images/search-analyzer-logo.png";

export const Logo: React.FC = (props) => {
  return (
    <div className="mt-1">
      <img src={logo} alt="Searchanalyzer Logo" className="w-32" />
    </div>
  );
};
