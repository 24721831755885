import { queryCache } from "react-query";
import decode from "jwt-decode";
export const localStorageKey = "___auth_token___";

export interface User {
  accounts: Account[];
  exp: number;
  jti: string;
}

interface Account {
  account_id: string;
  role: string;
  name?:string;
  permissions: {
    [project_id: string]: string[];
  };
  account_type: string;
}

function setUserToken(token: string) {
  window.localStorage.setItem(localStorageKey, token);
}

async function getUser() {
  const token = window.localStorage.getItem(localStorageKey);
  if (!token) {
    return null;
  }
  return decode<User>(token);
}

function getUserToken() {
  return window.localStorage.getItem(localStorageKey)!;
}

async function hasRoleAccess(role: string) {
  const token = await getUser();
  return (
    token?.accounts.filter((account) => account.role === role) !== undefined
  );
}

function logout() {
  window.localStorage.removeItem(localStorageKey);
  queryCache.clear();
}

export { getUser, hasRoleAccess, logout, setUserToken, getUserToken };
