import React from "react";
import { toast as reactToastify } from "react-toastify";
import Alert, { AlertSeverity } from "../components/Alert";

const getToast = (severity: AlertSeverity) => (message: string) => ({
  closeToast,
}: {
  closeToast?: () => void;
}) => (
  <div className="flex items-center bg-gray-50 pr-4">
    <Alert severity={severity} messages={[message]} className="shadow-none" />
  </div>
);

const successToast = getToast("Success");
const errorToast = getToast("Error");

const toast = {
  success(message: string) {
    reactToastify(successToast(message));
  },
  error(message: string) {
    reactToastify(errorToast(message), { autoClose: 5000 });
  },
};

export default toast;
