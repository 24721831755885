import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "../utils/toast-notification";
import { useAuth } from "../context/auth-context";
import ApiClient from "./../utils/api-client";
import { useHistory, Link } from "react-router-dom";
import logo from "../images/search-analyzer-logo.png";
import { getUser } from "../auth-provider";
import { BaseMessage } from "../components/styled/form/FormErrorMessage";

const Login = () => {
  const { t } = useTranslation();
  const { loginUser } = useAuth();
  const history = useHistory();

  const [switchForm, setSwitchForm] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // to type the target correctly
    const target = e.target as typeof e.target & {
      email: { value: string };
      password: { value: string };
    };
    const email = target.email.value;
    const password = target.password.value;
    try {
      const res = await ApiClient.login(email, password);
      await loginUser(res?.data.token);
      const user = await getUser();
      if (user?.accounts?.[0].role === "superadmin") {
        history.push("/users-management");
      } else {
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error("Invalid credentials!");
    }
  };

  const forgotPasswordSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage("")
    const target = e.target as typeof e.target & {
      email: { value: string };
    };
    const email = target.email.value;

    const res: any = await ApiClient.forgotPassword(email);
    if (res?.response?.data?.message) {
      setErrorMessage(res.response.data.message)
    } else if (res?.message) {
      setErrorMessage(res.message)
    }

  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img src={logo} alt="Searchanalyzer Logo" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          {t("login.signin-header")}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {switchForm
            ?
            <form onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  {t("login.email")}
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>

              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  {t("login.password")}
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="password"
                    type="password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    {t("login.signin")}
                  </button>
                </span>
              </div>
            </form>
            :
            <form onSubmit={forgotPasswordSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  {t("login.email")}
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    type="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
                {errorMessage !== "" && <p className="text-red-600 text-xs mt-2">{errorMessage}</p>}
              </div>

              <div className="mt-4">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    Reset password
                  </button>
                </span>
              </div>
            </form>
          }


          <div className="mt-6">
            <div className="relative">
              {switchForm ?
                <div className="absolute inset-0 flex items-center text-sm">
                  Forgot your password?
                  <p onClick={() => setSwitchForm((prev: any) => !prev)} className="text-blue-500 ml-1 cursor-pointer">Reset Here</p>
                </div>
                :
                <div className="absolute inset-0 flex items-center text-sm">
                  Back to<p onClick={() => setSwitchForm((prev: any) => !prev)} className="text-blue-500 ml-1 cursor-pointer">Login</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
