import React, { Suspense } from "react";
import { useAuth } from "./context/auth-context";
import Login from "./pages/Login";
import { adminRoutes, routes } from "./routes";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import { User } from "./auth-provider";
import Layout from "./components/Layout";
import { LoadingSpinner } from "./components/LoadingSpinner";
import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.css";
import { CurrentProjectProvider } from "./hooks/useProjects";
import { ReactQueryDevtools } from "react-query-devtools";

interface ProtectedRoutes extends RouteProps {
  component: any;
  user: User | null;
}

function App() {
  const { user } = useAuth();

  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        {user ?
          user?.accounts?.[0]?.role === "superadmin" ?
            (
              <AdminLogin />
            )
            :
            (
              <CurrentProjectProvider>
                <AuthenticatedApp />
              </CurrentProjectProvider>
            ) : (
            <UnauthenticatedApp />
          )}
      </Suspense>
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </>
  );
}

function UnauthenticatedApp() {
  return <Login />;
}

function AdminLogin() {

  return <>
    <Switch>
      <Layout type="admin">
        <Route exact path="/">
          <Redirect to="/users-management" />
          {/*<Redirect to="/admin-dashboard" />*/}
        </Route>
        <AdminRoutes />
      </Layout>
    </Switch>
  </>
}

const AdminRoutes = () => {
  const auth = useAuth();
  return (
    <>
      {Object.values(adminRoutes).map((route) => {
        return (
          <PrivateRoute
            path={route.path}
            key={route.path}
            exact
            component={route.component}
            user={auth?.user}
          />
        );
      })}
    </>
  );
};

function AuthenticatedApp() {
  return (
    <>
      <Switch>
        <Layout>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <RenderRoutes />
        </Layout>
      </Switch>
    </>
  );
}

const RenderRoutes = () => {
  const auth = useAuth();
  const { user } = useAuth();
  return (
    <>
      {/*{Object.values(user?.accounts[0].account_id === "ea0e24c2-aa8f-4128-a64f-a2fc8ece2ef4" ? { ...routes, ...adminRoutes } : routes).map((route) => {*/}
      {Object.values(routes).map((route) => {
        return (
          <PrivateRoute
            path={route.path}
            key={route.path}
            exact
            component={route.component}
            user={auth?.user}
          />
        );
      })}
    </>
  );
};

const PrivateRoute = ({
  user,
  component: Component,
  ...rest
}: ProtectedRoutes) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/login`, state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
export default App;
