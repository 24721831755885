import React from "react";

export type AlertSeverity =
  | "Info"
  | "Error"
  | "Warning"
  | "Success"
  | "Loading";

type AlertType = {
  severity: AlertSeverity;
  messages?: any;
  isShown?: boolean;
  onCloseButton?: void;
  timer?: number;
  className?: string;
};
const Alert: React.FC<AlertType> = ({
  severity = "Info",
  messages,
  children,
  className,
}) => {
  let borderColor = "border-blue-500";
  switch (severity) {
    case "Error":
      borderColor = "border-red-500";
      break;
    case "Warning":
      borderColor = "border-yellow-300";
      break;

    case "Success":
      borderColor = "border-green-400";
      break;

    case "Loading":
      borderColor = "border-blue-500";
      break;

    default:
      break;
  }
  return (
    <div
      data-testid="general-message"
      className={`${className} flex flex-wrap bg-gray-50 shadow-lg bg-gray-50 rounded-sm border-l-8 ${borderColor}`}
    >
      <div className="w-full flex flex-wrap items-center">
        <div className="px-5 py-4">
          <div className="w-full text-sm text-gray-500">
            {messages?.map((message: string) => {
              return (
                <div key={message}>
                  <span>{message}</span>
                </div>
              );
            })}
          </div>
          <div className="w-full" data-testid="Alert-children-text">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
