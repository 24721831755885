import React from "react";
import "../styles/loading-spinner.css";
import image from "../images/logo.gif"

interface Props {
  show?: boolean;
}

export const LoadingSpinner: React.FC<Props> = ({ show = true }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="inset-0 fixed w-full h-full flex items-center justify-center bg-black z-200">
      <div className="rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"><img className="" src={image} /></div>
      {/*<img className="h-64 w-64" src={image}></img>*/}
      {/*<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64" />*/}
    </div >
  );
};
